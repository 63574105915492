.mobile-searchbar-v1 {

	.ic-slider {
		width: 17px;
		fill: #FFF;
		margin-right: 6px;
	}

	.pd-right {
		padding-right: 0.5rem;
	}

	.pd-left {
		padding-left: 0.5rem;
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;


		.fa-sharp {
			position: absolute;
			right: 1rem;
			bottom: 18px;
			color: var(--color-grey-dark);
			font-size: 22px;
			display: block;
			line-height: 21px;
			color: var(--btn-primary-bg-color);

			@media (max-width: 992px) {
				font-size: 16px;
				bottom: 8px;
				right: 10px;
			}
		}

	}

	.search-tocken {
		margin-top: 15px;
		margin-bottom: 5px;
		overflow-x: auto;
		white-space: nowrap;
	}

	.mysearch2 {

		display: flex;
		flex-wrap: nowrap;

		.my-search-token2 {
			border-radius: 15px;
			display: inline-block;
			background-color: var(--color-grey-normal);
			font-size: var(--font-size-md);
			padding: 5px 10px;
			margin-right: 5px;

			.fa-sharp {
				color: var(--font-color-main);
			}
		}

	}

	/* Optional: Styling for the scrollbar */
	.search-tocken::-webkit-scrollbar {
		height: 1px;
	}

	.search-tocken::-webkit-scrollbar-thumb {
		background-color: transparent;
		border-radius: 4px;
	}

	.search-tocken::-webkit-scrollbar-thumb:hover {
		background-color: transparent;
	}

	.reset-filter {
		font-size: var(--font-size-sm);
		text-decoration: none;
		color: var(--font-color-main);
	}

	.line {
		border-bottom: 1px solid var(--color-grey-normal);
	}

}