.search-result-header-mobile-v1 {

	.result-icon {
		fill: var(--btn-primary-bg-color);
		width: 30px;
		border: 1px solid var(--btn-primary-bg-color);
		padding: 3px;
		border-radius: 5px;
	}

	.found {
		font-weight: 400;
	}

	.list-group-item.active {
		background-color: var(--btn-primary-bg-color);
		border: 1px solid var(--btn-primary-bg-color);
	}

	.srt-bl-top {
		.flex-align-center {

			.btn-filter {
				font-size: var(--font-size-md);
				padding-right: 10px;
				display: inline-block;
			}
		}
	}

	.srt-bl-top {
		padding-bottom: 10px;
	}

}