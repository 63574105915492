.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);


	/*@media(max-width:1440px) {
		max-width: 95%;
	}*/

	@media(max-width:992px) {
		max-width: 100%;
		margin-bottom: 140px;
	}


	@media(max-height:500px) {
		min-height: 500px;
	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);
		border-radius: 15px;
		overflow: hidden;
		margin: 0 auto;
		max-width: 1210px;

		@media(max-width:1239px) {
			max-width: 1110px;
		}

		@media(max-width:1199px) {
			max-width: 930px;
		}

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			overflow: hidden;

			@media (max-height: 600px) {
				/*min-height: 680px;*/

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 600px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}


	.hero-header {
		display: block;
		position: absolute;
		z-index: 3;
		padding: 0 5px;
		max-width: 1050px;
		z-index: 3;
		padding: 0 5px;
		margin: 0 auto;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		margin-top: -35px;

		@media (max-width: 1199px) {
			max-width: 850px;
		}

		/*@media(max-width:992px) AND (min-height: 701px) {
			bottom: 36vh;
		}

		@media(max-width:992px) AND (max-height: 700px) {
			top: 20vh;
		}

		@media(min-width:999px) AND (min-height: 501px) {
			bottom: 25vh;
		}

		@media(min-width:992px) AND (max-height: 500px) {
			top: 30vh;
		}*/

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			.h1 {
				font-family: var(--hero-header-font-family);
				//font-size: 5vw;
				line-height: 1.1;
				color: #fff;
				/*text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);*/
				font-size: 58px;
				font-weight: 600;
				margin-bottom: 0px;

				/*@media (min-width: 1600px) {
					font-size: 80px;
				}*/

				@media (max-width: 1140px) {
					font-size: 58px;
				}

				@media (max-width: 992px) {
					font-size: 3rem;
					//line-height: 2rem;
				}

				@media (max-width: 576px) {
					font-size: 2rem;
					//line-height: 2rem;
				}

				@media (max-height: 600px) {
					font-size: 2.75rem;
					//line-height: 2.75rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				color: #fff;
				/*text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);*/
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 400;
				margin-bottom: 20px;
				letter-spacing: 1.5px;

				@media (max-width: 767px) {
					font-size: 14px;
					margin-bottom: 20px;
				}

				/*@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}*/
			}
		}
	}
}