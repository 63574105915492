.index-page-layout-v1 {
	/*	margin-top: var(--page-margin-top);*/
	margin-top: 76px;


	h2 {
		text-align: center;
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

	.cta-banner {
		background: url(RESOURCE/img/index_angebote.webp) no-repeat 0 0;
		padding: 50px;
		min-height: 450px;
		background-size: cover;
		display: flex;
		align-items: center;
		border-radius: 15px;
		overflow: hidden;
		position: relative;

		@media(max-width: 767px) {
			//min-height: auto;
			padding: 30px;
		}

		&:before {
			content: "";
			//background: linear-gradient(270deg, rgba(255, 255, 255, 0) 20%, rgb(0, 0, 0) 60%);
			background: rgba(0, 0, 0, 0.3);
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;

		}

		.cta-banner-info {
			color: #fff;
			text-transform: uppercase;
			position: relative;

			h2 {
				margin: 0 0 30px;
				color: #fff;
				font-size: 52px;
				font-weight: 600;
				text-align: left;
				line-height: 1.3;


				@media(max-width: 992px) {
					font-size: 3rem;
				}

				@media(max-width: 576px) {
					font-size: 2rem;
				}
			}

			span {
				font-size: 16px;
				font-weight: 400;
				margin-bottom: 20px;
				display: inline-block;
				letter-spacing: 1.5px;

				@media(max-width: 767px) {
					font-size: 14px;
				}
			}
		}

	}

	.btn-style1 {
		border-radius: 0;
		border: 1px solid #fff;
		color: #fff;
		padding: 12px 35px;
		text-transform: uppercase;

		@media(max-width: 767px) {
			padding: 8px 24px;
			font-size: 12px;
		}

		&:hover {
			background: #2d8ea0;
			border-color: #2d8ea0;
		}
	}

	.theme-tile-slider {
		.owl-item {
			padding: 5px;

			@media(max-width: 767px) {
				padding: 5px 0;
			}

			.item {
				transition: all .2s;
				overflow: hidden;
				position: relative;
				border-radius: 15px;


				a {
					&:before {
						content: "";
						background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 65%, rgb(0 0 0 / 80%));
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						//opacity: 0.3;
					}
				}


				&:hover {
					box-shadow: 0 0 8px 0 rgb(0 0 0 / 30%);
					transform: scale(1.015);
				}
			}


		}

		img {
			border-radius: 15px;
		}

		h3 {
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 30px;
			color: #fff;
			font-size: 25px;
			width: 100%;

			@media(max-width: 1199px) {
				padding: 20px 15px;
			}

			@media(max-width: 576px) {
				font-size: 18px;
			}
		}

	}
}