.static-view {
	overflow: hidden;

	.inner-banner {
		@media (max-width:767px) {
			margin-left: -15px;
			margin-right: -15px;
		}


		&:before {
			content: "";
			background: #000;
			position: absolute;
			left: 0;
			right: 0;
			height: 100%;
			opacity: 0;
			z-index: 0;
		}

		&.kontakt {
			background-image: url(RESOURCE/img/kontakt_hero3.webp);
		}

		&.faq {
			background-image: url(RESOURCE/img/faq_hero.webp);

		}

		&.ueber-uns {
			background-image: url(RESOURCE/img/ueber_uns_hero2.webp);
		}
	}
}