.searchbar-v1 {
	position: absolute;
	bottom: -35px;
	width: 100%;
	padding-left: 0;
	padding-right: 0;

	@media(max-width: 992px) {
		bottom: 5vh;
	}

	@media(max-width: 992px) {
		bottom: -110px;
	}

	.container {
		max-width: 1130px;

		@media(max-width: 1199px) {
			max-width: 860px;
		}

		@media(max-width: 992px) {
			max-width: 720px;
		}

		@media(max-width: 767px) {
			max-width: 95%;
		}
	}


	.tabs {
		padding-left: 20px;

		.tab {
			color: #fff;
			background-color: var(--color-secondary);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			padding: 6px 20px;
			opacity: 1;
			cursor: pointer;
			display: inline-block;


			&.active {
				background-color: var(--color-primary);
				color: #fff;
			}

		}

	}

	#search-obj-form {
		display: none;
	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: space-between;
		background-color: #fff;
		background-color: var(--color-white);
		flex-flow: wrap;
		border: 10px solid #dae9ed;
		border-radius: 8px;
		margin: 0px 0px;

		.input-group {
			padding: 0px;
		}

		.form-btn {
			margin-left: 0px !important;
		}

		.searchbox-obj {
			padding-top: 4px;
			padding-left: 12px;
		}

		.form-obj-flex {
			width: 90%;
			padding: 9px 15px;

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}

			.fa,
			.fa-sharp {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 36px;
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding-right: 30px;

			}

			.form-flex-area {
				position: relative;
			}

		}

		.form-flex {
			width: 30%;
			padding: 9px 15px;
			border-right: 1px solid #f4f4f4;


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 6px 12px;
			}


			.fa,
			.fa-sharp {
				position: absolute;
				left: 10px;
				top: 11px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid #ddd;
				padding: 8px 10px 8px 30px;
				min-height: 40px !important;
				font-size: 12px;
				line-height: 20px;
				padding-right: 30px;

				&:focus {
					border-color: #86b7fe !important;
				}
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding-bottom: 5px;
			}

			.btn {
				width: 101%;
				height: 50px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}