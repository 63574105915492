.faq-v1 {
	margin-top: var(--page-margin-top);

	.title {
		font-size: 1.4rem;
		font-size: var(--h3-font-size);
		padding: 10px 3px;
		font-weight: 600;
		margin-bottom: 10px;
		margin-top: 10px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		text-indent: 5px;
	}

	.accordion-button {
		font-size: 20px;
	}

	.accordion-button:not(.collapsed) {
		color: var(--bs-accordion-btn-color) !important;
		background-color: #dae9ed !important;
	}

	.accordion-button:focus {
		border: none !important;
		box-shadow: none !important;
	}

	.accordion-button:not(.collapsed):after {
		background-image: var(--bs-accordion-btn-icon) !important;
	}


}